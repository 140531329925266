<template>
  <div class="">
    <el-dialog
      title="修改密码"
      :visible.sync="dialogVisible"
      width="30%"
      center
      @close="closeDialog"
      :close-on-click-modal="false"
    >
      <el-form
        ref="form"
        :model="form"
        label-width="80px"
        :rules="rules"
      >
        <el-form-item
          label="原密码"
          prop="old_pass"
        >
          <el-input
            v-model="form.old_pass"
            show-password
          ></el-input>
        </el-form-item>
        <el-form-item
          label="新密码"
          prop="new_pass"
        >
          <el-input
            v-model="form.new_pass"
            show-password
          ></el-input>
        </el-form-item>
        <el-form-item
          label="确认密码"
          prop="new_pass_repeat"
        >
          <el-input
            v-model="form.new_pass_repeat"
            show-password
          ></el-input>
        </el-form-item>
      </el-form>
      <span
        slot="footer"
        class="dialog-footer"
      >
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button
          type="primary"
          @click="clickConfim"
        >确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { editPasswordApi } from '../api/user'
import { loginOutApi } from '../api/login'
export default {
  // 组件名称
  name: '',
  // 局部注册的组件
  components: {},
  // 组件参数 接收来自父组件的数据
  props: {},
  // 组件状态值
  data () {
    var validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入密码'))
      } else {
        if (this.form.new_pass_repeat !== '') {
          this.$refs.form.validateField('new_pass_repeat')
        }
        callback()
      }
    }
    var validatePass2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入密码'))
      } else if (value !== this.form.new_pass) {
        callback(new Error('两次输入密码不一致!'))
      } else {
        callback()
      }
    }
    return {
      dialogVisible: false,
      form: {
        old_pass: '',
        new_pass: '',
        new_pass_repeat: ''
      },
      rules: {
        old_pass: [
          { required: true, message: '请输入原密码', trigger: 'blur' }
        ],
        new_pass: [
          { validator: validatePass, trigger: 'blur' }
        ],
        new_pass_repeat: [
          { validator: validatePass2, trigger: 'blur' }
        ]
      }
    }
  },
  // 计算属性
  computed: {},
  // 侦听器
  watch: {},
  created () { },
  mounted () { },
  // 组件方法
  methods: {
    clickConfim () {
      this.$refs.form.validate(async (valid) => {
        if (!valid) return
        const res = await editPasswordApi(this.form)
        if (res && res.code === 0) {
          this.dialogVisible = false
          await loginOutApi()
          this.$message.success('修改成功，将退出重新登录')
          this.$store.commit('setUserInfo', null)

          sessionStorage.removeItem('token')
          sessionStorage.removeItem('userInfo')
          this.$router.replace('/login')
        }
      })
    },
    openDialog () {
      this.dialogVisible = true
    },
    closeDialog () {
      this.$refs.form.resetFields()
    }
  }
}
</script>

<style scoped lang="less">
/deep/.el-dialog {
  min-width: 500px !important;
}
</style>
